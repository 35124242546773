export const applicationDocTypes = {
  Application: 'Application',
  Receipt: 'Receipt',
  EmailScreenshot: 'EmailScreenshot',
  ConfirmationScreenshot: 'ConfirmationScreenshot',
};

export const applicationStatusTypes = {
  Preparation: 'Preparation',
  Submitted: 'Submitted',
  Approved: 'Approved',
  AdjustmentNeeded: 'AdjustmentNeeded',
  Denied: 'Denied',
  Appealed: 'Appealed',
  NotPursuing: 'NotPursuing',
  PendingApproval: 'PendingApproval',
};

export const otherApplicationDateTypes = {
  StartedOn: 'StartedOn',
  Day30: 'Day30',
  Day45: 'Day45',
  Day60: 'Day60',
  Day90: 'Day90',
  Today: 'Today',
  MedicaidNeededByDate: 'MedicaidNeededByDate',
};

export const applicationTypes = {
  NursingHome: 'NursingHome',
  Renewal: 'Renewal',
  Community: 'Community',
  Conversion: 'Conversion',
  CoverageDown: 'CoverageDown',
};

export const clinicalDocTypes = {
  Approval: 'Approval',
  Determination: 'Determination',
};

export const clinicalStatusTypes = {
  Referred: '1',
  NonReferred: '2',
  Requested: '3',
  Assessment: '4',
  MissingInformation: '5',
  MissingSignatures: '6',
  Approved: '7',
  Pending: '8',
  Denied: '9',
};

export const contactType = {
  CorporateFacility: 'CorporateFacility',
  Facility: 'Facility',
};

export const noteTypes = {
  Facility: 'Facility',
  FacilityContact: 'FacilityContact',
  CompanyContact: 'CompanyContact',
  ResidentGeneral: 'ResidentGeneral',
  ResidentLiability: 'ResidentLiability',
  ResidentLookback: 'ResidentLookback',
  ResidentSpenddown: 'ResidentSpenddown',
  UR: 'UR',
  CLINICAL: 'CLINICAL',
};

export const pasrrLevels = {
  One: 'One',
  Two: 'Two',
};

export const pasrrDeterminationTypes = {
  Permanent: '1',
  Day45: '2',
  Day60: '3',
  Day90: '4',
  Day180Convalescent: '5',
  Day30: '6',
  Year1: '7',
  Approved: '8',
  Denied: '9',
};

export const pasrrMethodTypes = {
  Verbal: '1',
  Received: '2',
};

export const quickAddTypes = {
  Task: 'Task',
  Application: 'Application',
  Notes: 'Notes',
  UR: 'UR',
  Document: 'Document',
};

export const states = [
  {
    name: 'Alabama',
    id: 'AL',
  },
  {
    name: 'Alaska',
    id: 'AK',
  },
  {
    name: 'Arizona',
    id: 'AZ',
  },
  {
    name: 'Arkansas',
    id: 'AR',
  },
  {
    name: 'California',
    id: 'CA',
  },
  {
    name: 'Colorado',
    id: 'CO',
  },
  {
    name: 'Connecticut',
    id: 'CT',
  },
  {
    name: 'Delaware',
    id: 'DE',
  },
  {
    name: 'District Of Columbia',
    id: 'DC',
  },
  {
    name: 'Florida',
    id: 'FL',
  },
  {
    name: 'Georgia',
    id: 'GA',
  },
  {
    name: 'Guam',
    id: 'GU',
  },
  {
    name: 'Hawaii',
    id: 'HI',
  },
  {
    name: 'Idaho',
    id: 'ID',
  },
  {
    name: 'Illinois',
    id: 'IL',
  },
  {
    name: 'Indiana',
    id: 'IN',
  },
  {
    name: 'Iowa',
    id: 'IA',
  },
  {
    name: 'Kansas',
    id: 'KS',
  },
  {
    name: 'Kentucky',
    id: 'KY',
  },
  {
    name: 'Louisiana',
    id: 'LA',
  },
  {
    name: 'Maine',
    id: 'ME',
  },
  {
    name: 'Maryland',
    id: 'MD',
  },
  {
    name: 'Massachusetts',
    id: 'MA',
  },
  {
    name: 'Michigan',
    id: 'MI',
  },
  {
    name: 'Minnesota',
    id: 'MN',
  },
  {
    name: 'Mississippi',
    id: 'MS',
  },
  {
    name: 'Missouri',
    id: 'MO',
  },
  {
    name: 'Montana',
    id: 'MT',
  },
  {
    name: 'Nebraska',
    id: 'NE',
  },
  {
    name: 'Nevada',
    id: 'NV',
  },
  {
    name: 'New Hampshire',
    id: 'NH',
  },
  {
    name: 'New Jersey',
    id: 'NJ',
  },
  {
    name: 'New Mexico',
    id: 'NM',
  },
  {
    name: 'New York',
    id: 'NY',
  },
  {
    name: 'North Carolina',
    id: 'NC',
  },
  {
    name: 'North Dakota',
    id: 'ND',
  },
  {
    name: 'Ohio',
    id: 'OH',
  },
  {
    name: 'Oklahoma',
    id: 'OK',
  },
  {
    name: 'Oregon',
    id: 'OR',
  },
  {
    name: 'Palau',
    id: 'PW',
  },
  {
    name: 'Pennsylvania',
    id: 'PA',
  },
  {
    name: 'Rhode Island',
    id: 'RI',
  },
  {
    name: 'South Carolina',
    id: 'SC',
  },
  {
    name: 'South Dakota',
    id: 'SD',
  },
  {
    name: 'Tennessee',
    id: 'TN',
  },
  {
    name: 'Texas',
    id: 'TX',
  },
  {
    name: 'Utah',
    id: 'UT',
  },
  {
    name: 'Vermont',
    id: 'VT',
  },
  {
    name: 'Virginia',
    id: 'VA',
  },
  {
    name: 'Washington',
    id: 'WA',
  },
  {
    name: 'West Virginia',
    id: 'WV',
  },
  {
    name: 'Wisconsin',
    id: 'WI',
  },
  {
    name: 'Wyoming',
    id: 'WY',
  },
];

export const submissionMethodTypes = {
  HandDelivered: 'HandDelivered',
  Fax: 'Fax',
  Email: 'Email',
  OnlineSubmission: 'OnlineSubmission',
  Mail: 'Mail',
};

export const transactionCategories = {
  Lookback: 'Lookback',
  Spenddown: 'Spenddown',
};

export const transactionPaymentTypes = {
  Cash: 'Cash',
  Check: 'Check',
  Transfer: 'Transfer',
};

export const transactionStatus = {
  Ok: 'Ok',
  Flagged: 'Flagged',
};

export const uploadDocumentCategories = {
  Application: 'Application',
  Eligibility: 'Eligibility',
  Clinical: 'Clinical',
  Liability: 'Liability',
  Profile: 'Profile',
  Other: 'Other',
  NRI: 'NRI',
};

export const companySettingTypes = {
  ApplicationSubmissionValidation: 'ApplicationSubmissionValidation',
  NewAdmissionsTagWithUR: 'NewAdmissionsTagWithUR',
  DisableCoordinatorsFromMarkingOtherTasks:
    'DisableCoordinatorsFromMarkingOtherTasks',
  DisableCoordinatorsFromEditingOtherTasks:
    'DisableCoordinatorsFromEditingOtherTasks',
  HideAuthRequestedAndNextUpdateDue: 'HideAuthRequestedAndNextUpdateDue',
  MspPayerValidation: 'MspPayerValidation',
  PDPM: 'PDPM',
  DualDiagnosisForm: 'DualDiagnosisForm',
  HospiceMedicareValidation: 'HospiceMedicareValidation',
  DefaultPushDocsToEMR: 'DefaultPushDocsToEMR',
};

export const companySettingGroups = {
  Workflow: [
    companySettingTypes.ApplicationSubmissionValidation,
    companySettingTypes.NewAdmissionsTagWithUR,
    companySettingTypes.MspPayerValidation,
    companySettingTypes.PDPM,
    companySettingTypes.DualDiagnosisForm,
    companySettingTypes.HospiceMedicareValidation,
    companySettingTypes.DefaultPushDocsToEMR,
  ],
  Permission: [
    companySettingTypes.DisableCoordinatorsFromMarkingOtherTasks,
    companySettingTypes.DisableCoordinatorsFromEditingOtherTasks,
  ],
  Fields: [companySettingTypes.HideAuthRequestedAndNextUpdateDue],
};

export const userTypes = {
  Admin: 'Admin',
  Coordinator: 'Coordinator',
  Tracker: 'Tracker',
};

export const userSettingTypes = {
  MuteNriTrackerNotification: 'MuteNriTrackerNotification',
  QuickAddTypesPinned: 'QuickAddTypesPinned', //deprecated
};

export const trackedViewType = {
  ResidentProfile: 'ResidentProfile',
  Application: 'Application',
};

export const coverageTypes = {
  Policy: 'Policy',
  PrivatePay: 'PrivatePay',
};

export const coveragePeriodPositions = {
  Primary: 'Primary',
  Secondary: 'Secondary',
};

export const amountTypes = {
  Percent: 'Percent',
  WholeNumber: 'WholeNumber',
};

export const adtTypes = {
  Admission: 'Admission',
  Discharge: 'Discharge',
  Transfer: 'Transfer',
  Deceased: 'Deceased',
};

//use for stay details, not for AC
export const residentStatusTypes = {
  NewAdmission: 'NewAdmission',
  PlanNeeded: 'PlanNeeded',
  ShortTerm: 'ShortTermOnly',
  LongTerm: 'LongTerm',
};

export const companyTypes = {
  Application: 'ApplicationService',
  Facility: 'Facility',
};

export const DateRangeFilterOptions = {
  None: { id: '', name: '---' },
  All: { id: 'All', name: 'All Dates' },
  Today: { id: 'Today', name: 'Today' },
  ThisWeek: { id: 'ThisWeek', name: 'This Week' },
  ThisMonth: { id: 'ThisMonth', name: 'This Month' },
  ThisAndNextMonth: { id: 'ThisAndNextMonth', name: 'This and Next Month' },
  Last30Days: { id: 'Last30Days', name: 'Last 30 Days' },
  Last60Days: { id: 'Last60Days', name: 'Last 60 Days' },
  MoreThan60Days: { id: 'MoreThan60Days', name: 'More than 60 Days' },
};

export const RecentActivityViewFilter = {
  All: 'All',
  Residents: 'Residents',
  Applications: 'Applications',
};

export const CoveragePeriodExceptionTypes = {
  Ignore: 'Ignore',
  WriteOff: 'WriteOff',
};

export const PermissionClaims = {
  CoverageExceptionEditClaim: 'CoverageExceptionEditClaim',
  CompanyEditClaim: 'CompanyEditClaim',
  SuperAdminEditClaim: 'SuperAdminEditClaim',
  MultiCompanyUserClaim: 'MultiCompanyUserClaim',
  CompanyListEditClaim: 'CompanyListEditClaim',
  CompanyApplicationTagEditClaim: 'CompanyApplicationTagEditClaim',
};

export const AnnouncementTypes = {
  BannerOnly: 'BannerOnly',
  PopUpOnly: 'PopUpOnly',
  BannerAndPopUp: 'BannerAndPopUp',
};

export const ActivityEntityType = {
  Application: 'Application',
  Note: 'Note',
  Todo: 'Todo',
  File: 'File',
};

export const AuditEventType = {
  TaskCreated: 'TaskCreated',
  FileCreated: 'FileCreated',
  TaskCompleted: 'TaskCompleted',
  ApplicationIsOutsourced: 'ApplicationIsOutsourced',
  ApplicationInfoEdit: 'ApplicationInfoEdit',
  NoteEdit: 'NoteEdit',
};

export const PayerTypes = {
  Medicare: 'Medicare',
  HMO: 'HMO',
  CommercialHMO: 'CommercialHMO',
  ManagedMedicareHMO: 'ManagedMedicareHMO',
  MedicareAdvantage: 'MedicareAdvantage',
  ISNP: 'ISNP',
  Medicaid: 'Medicaid',
  HospiceMedicaid: 'HospiceMedicaid',
  ManagedMedicaid: 'ManagedMedicaid',
  PrivatePay: 'PrivatePay',
  HospiceRespite: 'HospiceRespite',
  GIPHospice: 'GIPHospice',
};

export const SkilledNursingPayerTypes = [
  PayerTypes.CommercialHMO,
  PayerTypes.HMO,
  PayerTypes.ISNP,
  PayerTypes.ManagedMedicareHMO,
  PayerTypes.MedicareAdvantage,
];

export const ResidentDateType = {
  MedicaidNeededBy: 'MedicaidNeededBy',
  ApplicationAppealDueDate: 'ApplicationAppealDueDate',
  ApplicationAppealHearingDate: 'ApplicationAppealHearingDate',
  ApplicationRenewalDate: 'ApplicationRenewalDate',
  ApplicationPostSubmitMilestoneDate: 'ApplicationPostSubmitMilestoneDate',
  ResidentAuthExpirationDate: 'ResidentAuthExpirationDate',
  ClinicalEvaluationExpirationDate: 'ClinicalEvaluationExpirationDate',
  DocumentDueDate: 'DocumentDueDate',
  TodoDueDate: 'TodoDueDate',
};

export const ResidentCaseViews = {
  Application: 'application',
  Census: 'census',
  Clinical: 'clinical',
  Documents: 'documents',
  Eligibility: 'eligibility',
  Liability: 'liability',
  Notes: 'notes',
  Profile: 'profile',
  Todos: 'todos',
};

export const CaseNavigationSource = {
  todos: 'todos',
  dashboard: 'dashboard',
  board: 'board',
};

export const VendorDataTypes = {
  RfmsFacilityList: 'RfmsFacilityList',
  PccDocumentCategories: 'PccDocumentCategories',
};

export const MaritalStatus = {
  Married: 'Married',
};

export const ResidentOverviewQueryParams = {
  fromCategory: 'from-category',
  view: 'view',
  applicationId: 'application-id',
};

export const SITE_HEADER_HEIGHT = 64;
export const SIDEBAR_RIGHT_WIDTH = 64; // TODO replace magic numbers with this constant

export const QuickActionType = {
  None: 'none',
  Application: 'application',
  Notes: 'notes',
  Print: 'print',
  Tasks: 'tasks',
};

export const ResidentClinicalStatus = {
  Completed: 'Completed',
  Denied: 'Denied',
  InProgress: 'InProgress',
  Needed: 'Needed',
  NotNeeded: 'NotNeeded',
  NotStarted: 'NotStarted',
};

export const RfmsStatus = {
  NA: 'NA',
  Open: 'Open',
  Closed: 'Closed',
  Missing: 'Missing',
};

export const SystemWorkflows = {
  ResidentScreening: 'ResidentScreening',
  Application: 'Application',
  ClinicalEvaluation: 'ClinicalEvaluation',
  PASRR: 'PASRR',
  FinancialScreening: 'FinancialScreening',
};
