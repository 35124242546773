import clsx from 'clsx';

import { Tooltip, Typography } from '@mui/material';
import TextInputFilter from '../filters/TextInputFilter';
import {
  CaseTagIcons,
  ChipField,
  DateInput,
  StatusField,
} from '../../../../components';
import { AllInclusiveIcon } from '../../../../themes';

import { getColorForApplicationStatus } from '../../caseDetails/application/applicationHelpers';
import {
  formatBool,
  formatCurrency,
  formatDate,
  getCaseStatusIconColor,
  companyTypes,
  DateRangeFilterOptions,
} from '../../../../lib';
import {
  caseSelectors,
  facilitySelectors,
  systemPersistSelectors,
  uiSelectors,
} from '../../../../state';
import { PayerNameWithStatus } from '../../../../components/payer';

export const FieldCategories = {
  Application: 'Application',
  Auth: 'Auth',
  Clinical: 'Clinical',
  Coverage: 'Coverage',
  Financial: 'Financial',
  General: 'General',
  Intake: 'Intake',
  Notes: 'Notes',
  UR: 'UR',
};

export const ListQueryParams = {
  applicationAssigneeIds: 'application-assignees',
  applicationStatuses: 'application-statuses',
  applicationStatusCategories: 'application-status-categories',
  applicationSubmitDateRange: 'applicationSubmitDateRange',
  applicationTags: 'app-tags',
  applicationTypes: 'application-types',
  archived: 'archived',
  assignedToMe: 'assignedToMe',
  authEndWithin14Days: 'auth-end-14',
  authStatuses: 'auth-statuses',
  caseStatuses: 'case-statuses',
  caseTags: 'case-tags',
  caseworkerIds: 'caseworkers',
  clinicalStatuses: 'clinical-statuses',
  coPayer: 'co-payer',
  dischargePlans: 'discharge-plans',
  facilityIds: 'facilities',
  facilityStateIds: 'facility-states',
  filingStateIds: 'filing-states',
  hasIncomeData: 'hasIncomeData',
  hasOpenApplications: 'hasOpenApplications',
  hasPending: 'hasPending',
  hasPrivate: 'hasPrivate',
  hearingDateRange: 'hearingDateRange',
  isBleeding: 'isBleeding',
  hasDocsDue: 'hasDocsDue',
  isMissingCoverage: 'isMissingCoverage',
  isOutsourced: 'outsourced',
  isResidentActive: 'resident-active',
  isSkilledNursing: 'skilled-nursing',
  listType: 'list-type',
  medicaidCaseworkerIds: 'medicaid-caseworker',
  medicaidNeededByFromDate: 'medicaid-needed-by-from',
  medicaidNeededByToDate: 'medicaid-needed-by-to',
  medicaidNeededWithinLast60Days: 'medicaid-needed-60',
  missingFullRange: 'MissingFullRange',
  missingPartialRange: 'MissingPartialRange',
  nextCaseworkerRequestDeadlineRange: 'nextCaseworkerRequestDeadlineRange',
  noApplication: 'no-application',
  noMedicaidRequest: 'no-medicaid-request',
  nriStatuses: 'nri-statuses',
  pasrr1Statuses: 'pasrr1-statuses',
  pasrr2Statuses: 'pasrr2-statuses',
  payerName: 'payer-name',
  payerTypes: 'payer-types',
  residentClinicalStatuses: 'resident-clinical-statuses',
  rfmsStatuses: 'rfms-statuses',
  showArchived: 'showArchived',
};

// #region shared fields and filters between all categories
/**
 *  optional companyType property on fields and filters - if specified then its specific to that companyType, otherwise it applies to all.
 *  pull in these filters and fields in the category-specific config files to set up fields and filters for a category.
 *  In those files:
 *  which fields are defaults for a list type:
 *      general= true or defaultLists (also optional) contains your list type.
 *      otherwise it will only be found in the customize columns modal.
 *  for filters - docked ones:
 *      generalDocked = true or dockedLists (also optional) contains your list type.
 *      otherwise it will be in the 'more' filters dropdown
 */

// ** label_AC and exportHeaders_AC are optional overrides for applicationCompany. Leave it out to use the regular labels
export const SharedFields = {
  ResidentName: {
    display: (
      {
        firstName,
        lastName,
        maritalStatus,
        middleName,
        isResidentActive,
        caseTagsDisplay,
      },
      classes,
    ) => (
      <div>
        <StatusField
          value={
            <Typography
              className={clsx(classes.residentNameField, classes.capitalize)}
            >
              {[`${lastName},`, firstName, middleName]
                .filter((n) => n)
                .join(' ')}
              {maritalStatus === 'Married' && (
                <Tooltip title='Married'>
                  <AllInclusiveIcon />
                </Tooltip>
              )}
            </Typography>
          }
          badgeClassName={isResidentActive ? 'active' : 'inactive'}
        />
        <CaseTagIcons caseTags={caseTagsDisplay} />
      </div>
    ),
    exportHeaders: [
      { key: 'firstName', label: 'First name' },
      { key: 'lastName', label: 'Last name' },
      { key: 'maritalStatus', label: 'Marital status' },
      {
        key: 'isResidentActive',
        label: 'Active',
        formatter: (val) => formatBool(val, 'Active', 'Inactive'),
      },
      {
        key: 'caseTagsDisplay',
        label: 'Tags',
        formatProperty: 'name',
      },
    ],
    id: 'ResidentName',
    label: 'Name',
    profileQueryString: '',
    width: 200,
    fieldCategory: FieldCategories.General,
  },
  FacilityName: {
    display: ({ facilityName }) => facilityName,
    exportHeaders: [{ key: 'facilityName', label: 'Facility' }],
    id: 'FacilityName',
    label: 'Facility',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  ReferralSource: {
    display: ({ facilityName }) => facilityName,
    exportHeaders: [{ key: 'facilityName', label: 'Referral Source' }],
    id: 'ReferralSource',
    label: 'Referral Source',
    profileQueryString: 'view=profile',
    orderBy: 'FacilityName', //specify the orderby since it doesnt match up with the id
    companyType: companyTypes.Application,
    fieldCategory: FieldCategories.General,
  },
  CaseStatus: {
    display: (record, classes) => (
      <ChipField
        record={record}
        source='caseStatusDisplay'
        className={classes.statusChip}
        chipProps={{
          style: { background: getCaseStatusIconColor(record.caseStatus) },
        }}
      />
    ),
    exportHeaders: [{ key: 'caseStatusDisplay', label: 'Resident Plan' }],
    exportHeaders_AC: [{ key: 'caseStatusDisplay', label: 'Client Status' }],
    id: 'CaseStatus',
    label: 'Resident Plan',
    label_AC: 'Client Status',
    profileQueryString: '',
    fieldCategory: FieldCategories.General,
  },
  PayerName: {
    display: ({ payerName, payerIsPending }) => (
      <PayerNameWithStatus
        payerName={payerName}
        payerIsPending={payerIsPending}
      />
    ),
    exportHeaders: [{ key: 'payerName', label: 'Payer' }],
    id: 'PayerName',
    label: 'Primary Payer',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  ApplicationStatus: {
    display: (record, classes) =>
      record.applicationStatusDisplay && (
        <ChipField
          record={record}
          source='applicationStatusDisplay'
          label='Application status'
          className={classes.statusChip}
          chipProps={{
            style: {
              background: getColorForApplicationStatus(record.applicationStatus)
                .color,
            },
          }}
        />
      ),
    exportHeaders: [
      { key: 'applicationStatusDisplay', label: 'Application Status' },
    ],
    id: 'ApplicationStatus',
    label: 'Application Status',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  DateForCurrentApplicationStatus: {
    display: ({ dateForCurrentApplicationStatus }) =>
      formatDate(dateForCurrentApplicationStatus),
    exportHeaders: [
      {
        key: 'dateForCurrentApplicationStatus',
        label: 'Application Status Date',
        format: 'date',
      },
    ],
    id: 'DateForCurrentApplicationStatus',
    label: 'Application Status Date',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  DaysForCurrentApplicationStatus: {
    display: ({ daysForCurrentApplicationStatus }) =>
      daysForCurrentApplicationStatus,
    exportHeaders: [
      {
        key: 'daysForCurrentApplicationStatus',
        label: 'Days in Application Status',
      },
    ],
    id: 'DaysForCurrentApplicationStatus',
    label: 'Days in Application Status',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  MedicaidNeededBy: {
    display: ({ anticipatedMedicaidDate, medicaidNeededBy }) => (
      <Tooltip
        title={
          anticipatedMedicaidDate
            ? `Anticipated Medicaid Date: ${formatDate(
                anticipatedMedicaidDate,
              )}`
            : ''
        }
      >
        <div
          style={{
            color:
              anticipatedMedicaidDate &&
              medicaidNeededBy &&
              new Date(anticipatedMedicaidDate) > new Date(medicaidNeededBy)
                ? '#f44336'
                : 'auto',
          }}
        >
          {formatDate(medicaidNeededBy)}
        </div>
      </Tooltip>
    ),
    exportHeaders: [
      {
        key: 'medicaidNeededBy',
        label: 'Medicaid Needed By',
        format: 'date',
      },
      {
        key: 'anticipatedMedicaidDate',
        label: 'Anticipated Medicaid Date',
        format: 'date',
      },
    ],
    id: 'MedicaidNeededBy',
    label: 'Medicaid Needed By',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Coverage,
  },
  ApplicationInfo: {
    display: ({ applicationInfo }) =>
      applicationInfo?.length > 50
        ? `${applicationInfo.substring(0, 50)}...`
        : applicationInfo,
    exportHeaders: [
      {
        key: 'applicationInfo',
        label: 'Application Info',
      },
    ],
    id: 'ApplicationInfo',
    label: 'Application Info',
    profileQueryString: 'view=application',
    disableSort: true,
    fieldCategory: FieldCategories.Application,
  },
  CoPayerName: {
    display: ({ coPayerName, coPayerIsPending }) => (
      <PayerNameWithStatus
        payerName={coPayerName}
        payerIsPending={coPayerIsPending}
      />
    ),
    exportHeaders: [
      {
        key: 'coPayerName',
        label: 'Co Payer',
      },
    ],
    id: 'CoPayerName',
    label: 'Co Payer',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.Coverage,
  },
  IncomeNetAmount: {
    display: ({ incomeNetAmount }) => formatCurrency(incomeNetAmount) || '--',
    exportHeaders: [
      {
        key: 'incomeNetAmount',
        label: 'Income Net Amount',
        format: 'currency',
      },
    ],
    id: 'IncomeNetAmount',
    label: 'Income Net Amount',
    profileQueryString: 'view=liability',
    fieldCategory: FieldCategories.Financial,
  },
  IncomePaymentStatus: {
    display: ({ incomePaymentStatusDisplay }, classes) => (
      <div
        className={
          incomePaymentStatusDisplay === 'Needed' ? classes.errorText : ''
        }
      >
        {incomePaymentStatusDisplay}
      </div>
    ),
    exportHeaders: [
      {
        key: 'incomePaymentStatusDisplay',
        label: 'Income Payment',
      },
    ],
    id: 'IncomePaymentStatus',
    label: 'Income Payment',
    profileQueryString: 'view=liability',
    fieldCategory: FieldCategories.Financial,
  },
  AdmitDate: {
    display: ({ admitDate }) => formatDate(admitDate),
    exportHeaders: [
      {
        key: 'admitDate',
        label: 'Admit Date',
        format: 'date',
      },
    ],
    id: 'AdmitDate',
    label: 'Admit Date',
    profileQueryString: 'view=census',
    companyType: companyTypes.Facility,
    fieldCategory: FieldCategories.General,
  },
  MedicaidCaseworker: {
    display: ({ medicaidCaseworker }) => medicaidCaseworker,
    exportHeaders: [
      {
        key: 'medicaidCaseworker',
        label: 'Medicaid Caseworker',
      },
    ],
    id: 'MedicaidCaseworker',
    label: 'Medicaid Caseworker',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  ApplicationType: {
    display: ({ applicationTypeDisplay }) => applicationTypeDisplay,
    exportHeaders: [
      {
        key: 'applicationTypeDisplay',
        label: 'Application Type',
      },
    ],
    id: 'ApplicationType',
    label: 'Application Type',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  LatestApplicationNoteDate: {
    display: ({ latestApplicationNoteDate }) =>
      formatDate(latestApplicationNoteDate),
    exportHeaders: [
      {
        key: 'latestApplicationNoteDate',
        label: 'Latest Application Note Date',
        format: 'date',
      },
    ],
    id: 'LatestApplicationNoteDate',
    label: 'Latest Application Note Date',
    profileQueryString: 'view=notes',
    fieldCategory: FieldCategories.Application,
  },
  MedicalRecordNumber: {
    display: ({ medicalRecordNumber }) => medicalRecordNumber,
    exportHeaders: [
      { key: 'medicalRecordNumber', label: 'Medical Record Number' },
    ],
    id: 'MedicalRecordNumber',
    label: 'Medical Record Number',
    profileQueryString: 'view=profile',
    fieldCategory: FieldCategories.General,
  },
  NextCaseworkerRequestDeadline: {
    display: ({ nextCaseworkerRequestDeadline }) =>
      formatDate(nextCaseworkerRequestDeadline),
    exportHeaders: [
      {
        key: 'nextCaseworkerRequestDeadline',
        label: 'Caseworker Request Due Date',
        format: 'date',
      },
    ],
    id: 'NextCaseworkerRequestDeadline',
    label: 'Caseworker Request Due Date',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
  ApplicationSubmitDate: {
    display: ({ applicationSubmittedDate }) =>
      formatDate(applicationSubmittedDate),
    exportHeaders: [
      {
        key: 'applicationSubmittedDate',
        label: 'Application Submitted',
        format: 'date',
      },
    ],
    id: 'ApplicationSubmitDate',
    label: 'Application Submitted',
    profileQueryString: 'view=application',
    fieldCategory: FieldCategories.Application,
  },
};

// ** label_AC, searchPlaceholder_AC and menuItemsSelector_AC are optional overrides for applicationCompany. Leave it out to use the regular labels and selectors.
export const SharedFilters = {
  assignedToMe: {
    defaultValue: false,
    label: 'Assigned to me',
    name: 'assignedToMe',
    queryParam: ListQueryParams.assignedToMe,
    type: 'checkbox',
  },
  showArchived: {
    defaultValue: false,
    general: true,
    label: 'Show Archived',
    name: 'showArchived',
    queryParam: ListQueryParams.archived,
    type: 'checkbox',
  },
  payerTypes: {
    defaultValue: [],
    label: 'Payer Type',
    menuItemsSelector: (state) => systemPersistSelectors.payerTypes(state),
    name: 'payerTypes',
    queryParam: ListQueryParams.payerTypes,
    type: 'multi',
    searchPlaceholder: 'Find payer types...',
    companyType: companyTypes.Facility,
  },
  payerName: {
    defaultValue: '',
    display: (props) => <TextInputFilter {...props} />,
    label: 'Payer Name',
    name: 'payerName',
    queryParam: ListQueryParams.payerName,
    companyType: companyTypes.Facility,
  },
  hasCoPayer: {
    defaultValue: '',
    label: 'Co payer',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Has Co Payer' },
      { id: false, name: 'No Co Payer' },
    ],
    name: 'hasCoPayer',
    queryParam: ListQueryParams.hasCoPayer,
    companyType: companyTypes.Facility,
  },
  isResidentActive: {
    defaultValue: '',
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Active Only' },
      { id: false, name: 'Inactive Only' },
    ],
    label: 'Resident Active/Inactive',
    name: 'isResidentActive',
    queryParam: ListQueryParams.isResidentActive,
    companyType: companyTypes.Facility,
  },
  facilityIds: {
    defaultValue: [],
    label: 'Facility',
    menuItemsSelector: (state) => facilitySelectors.facilityOptions(state),
    name: 'facilityIds',
    queryParam: ListQueryParams.facilityIds,
    type: 'multi',
    searchPlaceholder: 'Find facilities...',
    label_AC: 'Referral Source',
    searchPlaceholder_AC: 'Find referral sources...',
  },
  facilityStateIds: {
    defaultValue: [],
    label: 'State',
    menuItemsSelector: (state) => systemPersistSelectors.states(state),
    name: 'facilityStateIds',
    queryParam: ListQueryParams.facilityStateIds,
    type: 'multi',
    searchPlaceholder: 'Find states...',
    companyType: companyTypes.Facility,
  },
  filingStateIds: {
    defaultValue: [],
    label: 'Filing State',
    menuItemsSelector: (state) => systemPersistSelectors.states(state),
    name: 'filingStateIds',
    queryParam: ListQueryParams.filingStateIds,
    type: 'multi',
    searchPlaceholder: 'Find filing states...',
    companyType: companyTypes.Application,
  },
  caseStatuses: {
    defaultValue: [],
    label: 'Resident Plan',
    menuItemsSelector: (state) =>
      systemPersistSelectors.facilityCaseTypes(state),
    menuItemsSelector_AC: (state) => systemPersistSelectors.acCaseTypes(state),
    name: 'caseStatuses',
    queryParam: ListQueryParams.caseStatuses,
    type: 'multi',
    searchPlaceholder: 'Find resident plans...',
    searchPlaceholder_AC: 'Find client statuses...',
    label_AC: 'Client Status',
  },
  caseTags: {
    defaultValue: [],
    name: 'caseTagIds',
    label: 'Tags',
    menuItemsSelector: (state) => caseSelectors.companyCaseTagOptions(state),
    queryParam: ListQueryParams.caseTags,
    type: 'multi',
    searchPlaceholder: 'Find tags...',
  },
  caseworkerIds: {
    defaultValue: [],
    label: 'Internal Caseworker',
    menuItemsSelector: (state) => uiSelectors.potentialCompanyUsers(state),
    name: 'caseworkerIds',
    queryParam: ListQueryParams.caseworkerIds,
    type: 'multi',
    searchPlaceholder: 'Find internal caseworkers...',
  },
  medicaidNeededByFromDate: {
    defaultValue: '',
    display: ({ name, onChange, value }) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 24,
          }}
        >
          <DateInput
            fullWidth
            sx={{ width: '100%' }}
            name={name}
            onChange={(e) => onChange(e.target.value)}
            label='From'
            value={value}
          />
        </div>
      );
    },
    label: 'Medicaid Needed By',
    name: 'medicaidNeededByFromDate',
    queryParam: ListQueryParams.medicaidNeededByFromDate,
    type: 'twoInOne',
    dependsOn: 'medicaidNeededByToDate',
    valueFormatter: (value) => formatDate(value),
  },
  medicaidNeededByToDate: {
    defaultValue: '',
    display: ({ name, onChange, value }) => (
      <DateInput
        fullWidth
        sx={{ width: '100%' }}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        label='To'
        value={value}
      />
    ),
    name: 'medicaidNeededByToDate',
    queryParam: ListQueryParams.medicaidNeededByToDate,
    type: 'dependant',
    primaryFilter: 'medicaidNeededByFromDate',
    valueFormatter: (value) => formatDate(value),
  },
  applicationTypes: {
    defaultValue: [],
    label: 'Application Type',
    menuItemsSelector: (state) =>
      systemPersistSelectors.facilityApplicationTypes(state),
    menuItemsSelector_AC: (state) =>
      systemPersistSelectors.acApplicationTypes(state),
    name: 'applicationTypes',
    queryParam: ListQueryParams.applicationTypes,
    type: 'multi',
    searchPlaceholder: 'Find application types...',
  },
  applicationStatuses: {
    defaultValue: [],
    label: 'Application Status',
    menuItemsSelector: (state) =>
      systemPersistSelectors.applicationStatuses(state),
    name: 'applicationStatuses',
    queryParam: ListQueryParams.applicationStatuses,
    type: 'multi',
    searchPlaceholder: 'Find application statuses...',
  },
  applicationStatusCategories: {
    defaultValue: [],
    label: 'Application Status Category',
    menuItemsSelector: (state) =>
      systemPersistSelectors.applicationStatusCategories(state),
    name: 'applicationStatusCategories',
    queryParam: ListQueryParams.applicationStatusCategories,
    type: 'multi',
    searchPlaceholder: 'Find application status categories...',
  },
  medicaidCaseworkerIds: {
    defaultValue: [],
    label: 'Medicaid Caseworker',
    menuItemsSelector: (state) => uiSelectors.medicaidCaseworkers(state),
    name: 'medicaidCaseworkerIds',
    queryParam: ListQueryParams.medicaidCaseworkerIds,
    type: 'multi',
    searchPlaceholder: 'Find Medicaid caseworkers...',
  },
  applicationAssigneeIds: {
    defaultValue: [],
    label: 'Application Assignee',
    menuItemsSelector: (state) => uiSelectors.potentialCompanyUsers(state),
    name: 'applicationAssigneeIds',
    queryParam: ListQueryParams.applicationAssigneeIds,
    type: 'multi',
    searchPlaceholder: 'Find application assignees...',
  },
  noMedicaidRequest: {
    defaultValue: false,
    label: 'No Medicaid Request',
    name: 'noMedicaidRequest',
    queryParam: ListQueryParams.noMedicaidRequest,
    type: 'checkbox',
  },
  medicaidNeededWithinLast60Days: {
    defaultValue: false,
    label: 'Medicaid Needed within Last 60 Days',
    name: 'medicaidNeededWithinLast60Days',
    queryParam: ListQueryParams.medicaidNeededWithinLast60Days,
    type: 'checkbox',
  },
  nextCaseworkerRequestDeadlineRange: {
    name: 'nextCaseworkerRequestDeadlineRange',
    defaultValue: '',
    label: 'Caseworker Request Due Date',
    type: 'selectMenu',
    menuItems: [
      DateRangeFilterOptions.None,
      DateRangeFilterOptions.All,
      DateRangeFilterOptions.Today,
      DateRangeFilterOptions.ThisWeek,
      DateRangeFilterOptions.ThisMonth,
      DateRangeFilterOptions.ThisAndNextMonth,
    ],
    queryParam: ListQueryParams.nextCaseworkerRequestDeadlineRange,
    disableSort: true,
  },
  applicationSubmitDateRange: {
    name: 'applicationSubmitDateRange',
    defaultValue: '',
    label: 'Application Submitted',
    type: 'selectMenu',
    menuItems: [
      DateRangeFilterOptions.None,
      DateRangeFilterOptions.All,
      DateRangeFilterOptions.ThisMonth,
      DateRangeFilterOptions.Last30Days,
      DateRangeFilterOptions.Last60Days,
      DateRangeFilterOptions.MoreThan60Days,
    ],
    queryParam: ListQueryParams.applicationSubmitDateRange,
    disableSort: true,
  },
  hasDocsDue: {
    name: 'hasDocsDue',
    defaultValue: '',
    label: 'Docs needed',
    queryParam: ListQueryParams.hasDocsDue,
    type: 'selectMenu',
    menuItems: [
      { id: '', name: 'All' },
      { id: true, name: 'Needs documents' },
      { id: false, name: 'Does not need documents' },
    ],
  },
};
// #endregion
